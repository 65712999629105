import * as React from "react"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

export default function PageSix() {
  function sendEmail(event) {
    event.preventDefault()
    let name = document.getElementById("name").value
    let email = document.getElementById("email").value
    let feedback = document.getElementById("feedback").value

    fetch("https://www.dataalp.com/contact", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: name, email: email, feedback: feedback }),
    }).then(res => {
      document.getElementById("contactUs").reset()
      document.getElementById("messageSent").classList.add("d-block")
    })
  }

  return (
    <Layout>
      <Seo title="Contact Us" />
      <Navbar />


      <section id="hero" style={{ paddingTop: "60px" }}>
        <div className="container py-5">
          <div className="row pt-5 pb-5 justify-content-between align-items-center">
            <div className="col-lg-6 pt-lg-0 order-1 order-lg-2 text-right" style={{textAlign: 'right'}}>
              <StaticImage
                src="../images/phone-call.svg"
                alt=""
                placeholder="none"
                quality="100"
              />
            </div>
            <div className="col-lg-6 pt-lg-0 order-1 order-lg-1 d-flex align-items-center">
              <div data-aos="zoom-out">
                <h1 className="fs-1">Contact Us</h1>
                <h2
                  className="fs-5"
                  style={{ textAlign: "justify", lineHeight: "1.3" }}
                >
                  Let’s talk about your website or project. Send us a message and we will be in touch within one business day.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

            <section>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 pt-lg-0 order-sm-12 order-md-1 order-lg-1 d-flex align-items-center">
              <div data-aos="zoom-out">
                <h3 className="mb-5">What We Offer</h3>
                <h2
                  className="fs-5"
                  style={{ textAlign: "justify", lineHeight: "1.3" }}
                >
                  This website is currently compatible for supervised and
                  unsupervised learning datasets. If you are not sure about your
                  dataset, the Alp still has you covered. Just upload your data
                  and Alp will according process your dataset and tell you its
                  story. However, DataAlp relies on small tips to maintain and
                  continue improving the customer experience by providing better
                  insights and stories for all kinds of dataset. There is always
                  more coming in terms of what this startup can provide as this
                  is only a start. So please be generous with your tips & do
                  leave us with the feedback on what needs to be improved.
                  Moreover, if there is a specific task you need Alp to help you
                  with, you can always fill the form under 'Hire An Alp' section
                  and an Alp will be there to get it done for you.
                </h2>
              </div>
            </div>
            <div className="col-lg-6 pt-lg-0 order-sm-1 order-md-2 order-lg-2 d-flex justify-content-center">
              <StaticImage
                src="../images/Financial-data-cuate.svg"
                alt=""
                placeholder="none"
                quality="100"
              />
            </div>
          </div>
        </div>
      </section>


      <Footer />
    </Layout>
  )
}
